var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getListTags,"server-items-length":_vm.getNumberTags,"hide-default-footer":"","data-test":"tagListList-dataTable"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{ref:'menu'+_vm.getListTags.indexOf(item),attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g({attrs:{"color":"transparent"}},on),[_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-card',[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorizationEdit},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-list-item',{attrs:{"disabled":!_vm.hasAuthorizationEdit},on:{"click":function($event){_vm.showTagDialog(_vm.getListTags.indexOf(item))}}},[_c('TagFormDialogEdit',{attrs:{"tag-name":item.name,"show":_vm.tagDialogShow[_vm.getListTags.indexOf(item)],"data-test":"tagFormDialogEdit-component"},on:{"update:show":function($event){_vm.$set(_vm.tagDialogShow, _vm.getListTags.indexOf(item), $event)},"update":function($event){return _vm.getTags()}}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])]),_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.hasAuthorizationRemove},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-list-item',{attrs:{"disabled":!_vm.hasAuthorizationRemove},on:{"click":function($event){_vm.showTagDelete(_vm.getListTags.indexOf(item))}}},[_c('TagDelete',{attrs:{"tag-name":item.name,"show":_vm.tagDeleteShow[_vm.getListTags.indexOf(item)],"data-test":"tagDelete-component"},on:{"update:show":function($event){_vm.$set(_vm.tagDeleteShow, _vm.getListTags.indexOf(item), $event)},"update":function($event){return _vm.getTags()}}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(" You don't have this kind of authorization. ")])])],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }